<template>
  <ErrorDialog :dialogVisible="errorDialogVisible" :errorMessage="errorMessage"
    @update:dialogVisible="errorDialogVisible = $event" />

  <div class="d-flex  mt-2 align-center">
    <v-text-field v-model="search" clearable density="compact" hide-details placeholder="Search"
      prepend-inner-icon="mdi-magnify" style="max-width: 300px;" variant="solo"></v-text-field>
  </div>
  <v-divider class="mb-2 mt-2"></v-divider>
  <v-data-table :items="movements" :headers="headers" items-per-page="25" density="compact" :hover="true" :search="search"
    style="font-family: monospace;">
    <template v-slot:[`item.monto`]="{ item }">
      <span v-if="item.monto > 0" style="color: blue;">{{ item.monto }}</span>
      <span v-else>{{ item.monto }}</span>
    </template>
    <template v-slot:[`item.payment`]="{ item }">
      <span v-if="!item.payment" style="background-color: orange;">__</span>
      <span v-else>{{ item.payment }}</span>
    </template>
    <template v-slot:[`item.conceptoPago`]="{ item }">
      <v-menu style="font-family: monospace;" :close-on-content-click="false" v-model="menu[item._id]">
        <template v-slot:activator="{ props }">
          <pre v-bind="props" class="text-decoration-underline">{{ item.conceptoPago ? item.conceptoPago : '+' }}</pre>
        </template>
        <v-list>
          <v-list-item>
            <v-list-item-title size="small">
              <v-text-field v-model="item.conceptoPago" hide-details style="width: 200px"></v-text-field>
              <v-btn color="primary" class="mt-2" @click="updateConceptoPago(item)">Update</v-btn>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    <template v-slot:[`item.urlCEP`]="{ item }">
      <v-btn v-if="item.urlCEP" color="blue" class="ml-2" :href="`${item.urlCEP}`" target="_blank">CEP</v-btn>
    </template>
 
  </v-data-table>

  <v-sheet style="max-width:400px">
      <v-autocomplete v-model="bank" :items="['amex', 'pricewallet', 'hsbc']" label="Bank" dense hide-details></v-autocomplete>
      <br />
      <v-file-input label="Upload file" v-model="file" @change="onFileChange"></v-file-input>

  </v-sheet>
</template>

<script>
import ErrorDialog from './ErrorDialog.vue';

import axios from 'axios';

export default {
  name: 'MovementPage',
  props: ['movement_filter'],
  components: {
    ErrorDialog,
  },
  data() {
    return {
      menu: [],
      file: null,
      bank: 'amex',
      movements: [],
      search: '',
      headers: [
        { title: "Bank", value: "bank"},
        { title: "Fecha Operacion", value: "fechaOperacion" },
        { title: "Fecha", value: "fecha" },
        { title: "Monto", value: "monto" },
        { title: "Concepto Pago", value: "conceptoPago" },
        { title: "Payment", value: "payment" },
        { title: "urlCEP", value: "urlCEP" },
        { title: "Details", value: "details" },
        { title: "Estado", value: "estado" },
        { title: "Clave Rastreo Devolucion", value: "claveRastreoDevolucion" },
        { title: "Causa Devolucion", value: "causaDevolucion"},
        { title: "tsLiquidacion", value: "tsLiquidacion"},
        { title: "Nombre Beneficiario", value: "nombreBeneficiario" },
        { title: "Clave Rastreo", value: "claveRastreo" },
        { title: "Referencia Numerica", value: "referenciaNumerica" },
        { title: "Institucion Ordenante", value: "institucionOrdenante" },
        { title: "Cuenta Ordenante", value: "cuentaOrdenante" },
        { title: "Nombre Ordenante", value: "nombreOrdenante" },
        { title: "Institucion Beneficiaria", value: "institucionBeneficiaria" },
        { title: "Cuenta Beneficiario", value: "cuentaBeneficiario" },
        { title: "Tipo Pago", value: "tipoPago" },
        { title: "Moneda", value: "moneda" },
        { title: "_id", value: "_id" },
      ]
    }
  },
  async created() {
    // watch the params of the route to fetch the data again
    this.$watch(
      () => this.$route.params,
      async () => {
        try {
          const response = await axios.get(process.env.VUE_APP_API_BASE_URL + '/movement/all', {
            params: this.$route.query
          });
          this.movements = response.data;
        } catch (error) {
          console.error('Failed to fetch movements:', error.message);
        }
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    )
  },
  async mounted() {
  },
  computed: {
  },
  methods: {
    onFileChange(e) {
      // send file to server
      this.file = e.target.files[0];
      console.log(this.file);
      let formData = new FormData();
      formData.append('file', this.file);
      axios.post(process.env.VUE_APP_API_BASE_URL + '/movement/upload-movements/' + this.bank, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then((response) => {
        // print response from server

        console.log(response.data);
        // reset file input
        this.file = null;
        // update movement filter
        this.$emit('update:movement_filter', 'all')
      }).catch((error) => {
        this.errorMessage = error.response.data.message
        this.errorDialogVisible = true
      })
    },
    async updateConceptoPago(movement) {
      try {
        const response = await axios.put(process.env.VUE_APP_API_BASE_URL + '/movement/update-concepto-pago', {
          _id: movement._id,
          __v: movement.__v,
          conceptoPago: movement.conceptoPago
        });
        movement = response.data;
        this.menu[movement._id] = false;
      } catch (error) {
        movement.conceptoPago = '';
        console.error('Failed to update conceptoPago:', error.message);
      }
    }
  },
}
</script>
