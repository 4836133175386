import { createRouter, createWebHistory } from 'vue-router';
import HelloWorld from '../components/HelloWorld.vue';
import LoginPage from '../components/LoginPage.vue';
import OperationPage from '../components/OperationPage.vue';
import MovementPage from '../components/MovementPage.vue';
import InvoicingInfo from '../components/InvoicingInfo.vue';
import store from '../store';

const routes = [
  { path: '/login', component: LoginPage, meta: {title: "Login"} },
  { path: '/', component: HelloWorld, meta: { requiresAuth: true, title: "Mose" } },
  { path: '/operation/:filter', name: 'operation', component: OperationPage, meta: { requiresAuth: true, title: 'Operation' } },
  { path: '/movement/upload-amex', name: 'movement', component: MovementPage, meta: { requiresAuth: true, title: 'Movement' } },
  { path: '/invoicingInfo', component: InvoicingInfo, meta: { requiresAuth: true, title: 'Invoicing Info' } },
//  { path: '/sabre/jr', name: 'jr', component: SabreJr, meta: { requiresAuth: true, title: 'JR' } },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

function isUserAuthenticated() {
    // Replace 'auth-token' with the key you use to store the token
    // Check if the token exists and is valid
    return store.state.isLoggedIn;
}

router.beforeEach((to, from, next) => {
    if (to.meta.requiresAuth && !isUserAuthenticated()) {
      next('/login');
    } else {
        next();
    }
});

export default router;