<template>
  <v-app id="inspire">

    <v-navigation-drawer v-model="drawer" v-if="isLoggedIn">
      <v-list active-class="deep-purple--text text--accent-4">
        <v-list-item to="/">
          <v-list-item-title>Mose Home</v-list-item-title>
        </v-list-item>

        <v-list-group>
          <template v-slot:activator="{ props }">
            <v-list-item v-bind="props" title="Operation"></v-list-item>
          </template>
          <v-list-item :to="{ name: 'operation', params: { filter: 'current' }}">
            <v-list-item-title>Current</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{ name: 'operation', params: { filter: 'archived' }, query: {transaction: 'refund', startDate: '', endDate: '', customer: ''}}">
            <v-list-item-title>Refund</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{ name: 'operation', params: { filter: 'archived' }, query: {transaction: 'inter transfer', startDate: '', endDate: '', customer: ''}}">
            <v-list-item-title>Inter Transfer</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{ name: 'operation', params: { filter: 'archived' }, query: {transaction: 'autoreversal', startDate: '', endDate: '', customer: ''}}">
            <v-list-item-title>Autoreversal</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{ name: 'operation', params: { filter: 'archived' }, query: {transaction: 'cobranza', startDate: '', endDate: '', customer: ''}}">
            <v-list-item-title>Cobranza</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{ name: 'operation', params: { filter: 'archived' }, query: {transaction: 'nomina', startDate: '', endDate: '', customer: ''}}">
            <v-list-item-title>Nomina</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{ name: 'operation', params: { filter: 'archived' }, query: {transaction: 'impuesto', startDate: '', endDate: '', customer: ''}}">
            <v-list-item-title>Impuesto</v-list-item-title>
          </v-list-item>

        </v-list-group>

        <v-list-group>
          <template v-slot:activator="{ props }">
            <v-list-item v-bind="props" title="Archived Operations"></v-list-item>
          </template>
          <v-list-item :to="{ name: 'operation', params: { filter: 'archived' }, query: {transaction: '', startDate: getDateNDaysBefore(30), endDate: '', customer: ''}}">
            <v-list-item-title>Last 30 days</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{ name: 'operation', params: { filter: 'archived' }, query: {transaction: '', startDate: getDateNDaysBefore(60), endDate: '', customer: ''}}">
            <v-list-item-title>Last 60 days</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{ name: 'operation', params: { filter: 'archived' }, query: {transaction: '', startDate: getDateNDaysBefore(90), endDate: '', customer: ''}}">
            <v-list-item-title>Last 90 days</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{ name: 'operation', params: { filter: 'archived' }, query: {transaction: '', startDate: getDateNDaysBefore(120), endDate: '', customer: ''}}">
            <v-list-item-title>Last 120 days</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{ name: 'operation', params: { filter: 'archived' }, query: {transaction: '', startDate: '', endDate: '', customer: ''}}">
            <v-list-item-title>ALL</v-list-item-title>
          </v-list-item>
        </v-list-group>
<!--

        <v-list-group>
          <template v-slot:activator="{ props }">
            <v-list-item v-bind="props" title="Sabre"></v-list-item>
          </template>
          <v-list-item :to="{ name: 'JR', params: {} }">
            <v-list-item-title>JR</v-list-item-title>
          </v-list-item>
        </v-list-group>
-->

        <v-list-group>
          <template v-slot:activator="{ props }">
            <v-list-item v-bind="props" title="Movement"></v-list-item>
          </template>
          <v-list-item :to="{ name: 'movement' }">
            <v-list-item-title>All</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{ name: 'movement', params: {}, query: { bank: 'STP', startDate: '', endDate: '', monto: '', creditOrDebit: '', hasPayment: ''}}">
            <v-list-item-title>STP</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{ name: 'movement', params: {}, query: { bank: 'AMEX', startDate: '', endDate: '', monto: '', creditOrDebit: '', hasPayment: ''}}">
            <v-list-item-title>AMEX</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{ name: 'movement', params: {}, query: { bank: 'HSBC', startDate: '', endDate: '', monto: '', creditOrDebit: '', hasPayment: ''}}">
            <v-list-item-title>HSBC</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{ name: 'movement', params: {}, query: { bank: 'PriceWallet', startDate: '', endDate: '', monto: '', creditOrDebit: '', hasPayment: ''}}">
            <v-list-item-title>PriceWallet</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{ name: 'movement', params: {}, query: { bank: '', startDate: '', endDate: '', monto: '', creditOrDebit: '', hasPayment: 'false'}}">
            <v-list-item-title>NoPayment</v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-list-group>
          <template v-slot:activator="{ props }">
            <v-list-item v-bind="props" title="Misc"></v-list-item>
          </template>
          <v-list-item :to="{ path: '/invoicingInfo' }">
            <v-list-item-title>Invoicing Info</v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-list-item @click="openLogoutDialog">
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar class="mb-10">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-app-bar-title>{{ title || "Mose" }}</v-app-bar-title>
      <v-spacer></v-spacer>
      <div class="text-center" v-if="isLoading">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
        <v-progress-circular indeterminate color="red"></v-progress-circular>
        <v-progress-circular indeterminate color="purple"></v-progress-circular>
        <v-progress-circular indeterminate color="green"></v-progress-circular>
        <v-progress-circular indeterminate color="amber"></v-progress-circular>
      </div>
      <v-spacer></v-spacer>
      ∎
    </v-app-bar>

    <v-dialog v-model="logoutDialog" max-width="500px">
      <v-card>
        <v-card-title>Logout</v-card-title>
        <v-card-text>Are you sure you want to logout?</v-card-text>
        <v-card-actions>
          <v-btn color="green darken-1" text @click="logoutAndCloseDialog">Yes</v-btn>
          <v-btn color="red darken-1" text @click="logoutDialog = false">No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-container fluid>
      <router-view />
    </v-container>
    <v-dialog v-model="dialogVisible" max-width="500px">
      <v-card>
        <v-card-title class="headline">Error</v-card-title>
        <v-card-text>{{ errorMessage }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialogVisible = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script setup>
import { ref, computed, watchEffect, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

const title = ref("");
const drawer = ref(null);
const logoutDialog = ref(false)
const store = useStore()
const route = useRoute()
const isLoggedIn = computed(() => store.state.isLoggedIn)
const isLoading = computed(() => store.state.isLoading)
const logout = () => {
  localStorage.removeItem('token')
  store.commit('setIsLoggedIn', false)
  location.reload();
}
const openLogoutDialog = () => {
  logoutDialog.value = true
}
const logoutAndCloseDialog = () => {
  logout()
  logoutDialog.value = false
}
watchEffect(() => {
  drawer.value = false;
});
watch(route, (newRoute) => {
  drawer.value = false;
});
watchEffect(() => {
  title.value = route.meta.title
  if (route.params.filter) {
    title.value += " " + route.params.filter.charAt(0).toUpperCase() + route.params.filter.slice(1)
  }
});
</script>

<script>
export default {
  name: 'App',
  data() {
    return {
      errorMessage: null,
      dialogVisible: false,
    }
  },
  methods: {
    getDateNDaysBefore(n) {
      const date = new Date();
      date.setDate(date.getDate() - n);
      return date.toISOString().split('T')[0];

    },
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
