<template>
  <ErrorDialog :dialogVisible="errorDialogVisible" :errorMessage="errorMessage"
    @update:dialogVisible="errorDialogVisible = $event" />

  <v-dialog v-model="dialog" max-width="600px" density="compact">
    <v-form ref="form" validate-on="submit blur" @submit.prevent="submitForm">
      <v-card density="compact">
        <v-card-item>
          <v-card-title>
            <strong>
              {{ dialog }} Operation
            </strong></v-card-title>
        </v-card-item>
        <v-card-text>
          <v-row>
            <v-col>
              <v-autocomplete v-model="operation.transaction" :items="config.detail.operation.transaction"
                auto-select-first :rules="[v => !!v || 'Transaction is required']" required
                label="Transaction"></v-autocomplete>
            </v-col>
            <v-col>
              <v-autocomplete v-model="operation.type" :items="config.detail.operation.type" auto-select-first
                :rules="[v => !!v || 'Type is required']" required label="Type"></v-autocomplete>
            </v-col>
          </v-row>
          <v-autocomplete v-model="operation.op" :items="config.detail.operation.op"
            :rules="[v => !!v || 'Operation is required']" label="Operation" auto-select-first required></v-autocomplete>
          <v-autocomplete v-model="operation.platform" :items="config.detail.operation.platform" label="Platform"
            :rules="[v => !!v || 'Platform is required']" required auto-select-first></v-autocomplete>
          <v-autocomplete v-model="operation.provider" label="Provider" :items="config.detail.operation.provider"
            auto-select-first></v-autocomplete>
          <v-text-field v-model="operation.customer" label="Customer" :rules="[v => !!v || 'Customer is required']"
            required></v-text-field>
          <v-row>
            <v-col>
              <v-text-field v-model="operation.reservation_code" label="Reservation Code"
                :rules="[v => !!v || 'Reservation Code is required']"></v-text-field>
            </v-col>
            <v-col>
              <v-text-field v-model="operation.ticket_number" label="Ticket Number"></v-text-field>
            </v-col>
          </v-row>
          <v-textarea v-model="operation.passengers" label="Passengers" :rules="[v => !!v || 'Passengers is required']"
            required></v-textarea>
          <v-textarea v-model="operation.description" label="Description"></v-textarea>
          <v-row>
            <v-col>

              <v-text-field v-model.number="operation.cost_total" label="Total Cost" type="number"
                :rules="[(v) => v !== null || 'Field is required', (v) => !isNaN(Number(v)) || 'Input must be a number']">
              </v-text-field>
            </v-col>
            <v-col>

              <v-text-field v-model.number="operation.cost_iva"
                :label="'IVA ' + (operation.cost_total / 1.16 * 0.16).toFixed(2)" type="number">
              </v-text-field>
            </v-col>
          </v-row>
          <v-text-field v-model.number="operation.sale_price"
            :label="`Sale Price ` + Math.ceil(operation.cost_total + 348) + ' / ' + Math.ceil(operation.cost_total + 580) + ' / ' + Math.ceil(operation.cost_total + 1000)"
            type="number"
            :rules="[
              (v) => v !== null || 'Field is required',
              (v) => !isNaN(Number(v)) || 'Input must be a number',
              (v) => operation.transaction === 'expense' || Number(v) >= operation.cost_total || 'Input must be greater than or equal to cost_total']"></v-text-field>
          <v-text-field v-model="localDate" label="Sale Date" readonly></v-text-field>
          <div class="d-flex justify-space-between mb-4 align-center">
            <v-btn color="primary" @click="this.operation.date = new Date()">Set Date to Today</v-btn>
            <v-spacer></v-spacer>
            <v-checkbox v-model="operation.credit" label="Credit" color="warning" hide-details
              :inline="true"></v-checkbox>
          </div>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-btn color="blue darken-1" text @click="dialog = null">Close</v-btn>
          <v-btn color="blue darken-1" text @click="cleanForm">Clean</v-btn>
          <v-spacer></v-spacer>
          <v-btn :color="dialog === 'Create' ? 'primary' : 'warning'" type="submit" :loading="loading">{{ dialog
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
  <v-sheet>
    <div class="d-flex  mt-2 align-center mb-4">
      <v-btn color="blue" @click="dialog = `Create`; this.operation = { ...this.operation };">+ Operation</v-btn>
      <v-btn color="green" class="ml-2"
        @click="createPaymentFromCustomer(selected_operations); selected_operations.splice(0, selected_operations.length);">
        + Payment From Customer {{ selectedTotal.sale_price }}
      </v-btn>
      <span class="ml-2">
        {{ this.selected_operations.length }} SELECTED
      </span>
      <v-spacer></v-spacer>
      <v-btn color="orange" class="ml-2"
        @click="createPaymentToProvider(selected_operations); selected_operations.splice(0, selected_operations.length);">
        + Payment to Provider {{ selectedTotal.cost_total }}
      </v-btn>
    </div>

    <div class="d-flex  mt-2 align-center">
      <v-text-field v-model="search" clearable density="compact" hide-details placeholder="Search"
        prepend-inner-icon="mdi-magnify" style="max-width: 300px;" variant="solo"></v-text-field>
      <v-text-field density="compact"  v-model="itin_pnr" placeholder="PNR" hide-details style="max-width: 100px" class="ml-2" variant="solo" bg-color="red"></v-text-field>
      <v-text-field density="compact"  v-model="itin_lastname" placeholder="Last Name" hide-details style="max-width: 150px" class="ml-2" variant="solo" bg-color="red"></v-text-field>
      <v-btn color="red" class="ml-2" :href="`https://services.tripcase.com/new/itineraryChron.html?lastName=${itin_lastname}&segment=print&action=printPreview&pnr=${itin_pnr}&language=7`" target="_blank">简体</v-btn>
      <v-btn color="red" class="ml-2" :href="`https://services.tripcase.com/new/itineraryChron.html?lastName=${itin_lastname}&segment=print&action=printPreview&pnr=${itin_pnr}&language=1`" target="_blank">ESP</v-btn>
      <v-btn color="red" class="ml-2" :href="`https://services.tripcase.com/new/itineraryChron.html?lastName=${itin_lastname}&segment=print&action=printPreview&pnr=${itin_pnr}&language=5`" target="_blank">ENG</v-btn>
      <v-btn color="red" class="ml-2" :href="`https://services.tripcase.com/new/itineraryChron.html?lastName=${itin_lastname}&segment=print&action=printPreview&pnr=${itin_pnr}&language=8`" target="_blank">繁体</v-btn>
      <v-spacer></v-spacer>
      <span @click="groupBy = groupBy.length > 0 ? [] : [{ key: 'customer', order: 'asc' }]">GroupByDK</span>
    </div>

  </v-sheet>
  <v-divider class="mb-2 mt-2"></v-divider>

  <v-dialog v-model="amountDialogVisible" max-width="300px">
    <v-card>
      <v-card-title class="headline">Enter an Amount from Customer</v-card-title>
      <v-card-text>
        <v-text-field v-model.number="paymentAmount" type="number" label="Number"></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" text
          @click="amountDialogVisible = false; this.operation.tmpCustomerAmount = paymentAmount;">OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>


  <v-dialog v-model="providerAmountDialogVisible" max-width="300px">
    <v-card>
      <v-card-title class="headline">Enter an Amount for Provider</v-card-title>
      <v-card-text>
        <v-text-field v-model.number="paymentAmount" type="number" label="Number"></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" text
          @click="providerAmountDialogVisible = false; this.operation.tmpProviderAmount = paymentAmount;">OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-data-table :items="operations" :headers="headers" :items-per-page="itemsPerPage" density="compact" show-select
    :group-by="groupBy" :hover="true" :search="search" v-model="selected_operations" item-value="_id"
    style="font-family: monospace;">
    <template v-slot:group-header="{ item, columns, toggleGroup, isGroupOpen }">
      <tr>
        <td :colspan="columns.length">
          <VBtn size="small" variant="text" :icon="isGroupOpen(item) ? '$expand' : '$next'" @click="toggleGroup(item)">
          </VBtn>
          {{ item.value }} ({{ item.items.length }}) {{ item.items.reduce((acc, cur) => {
            let totalPayment = 0;
            if (cur.raw.payments_from_customer && cur.raw.payments_from_customer.length > 0) {
              totalPayment = cur.raw.payments_from_customer.reduce((acc, payment) => {
                if (payment.payment.movement) {
                  return acc + payment.amount;
                } else {
                  return acc;
                }
              }, 0);
            }
            return acc + (cur.raw.sale_price - totalPayment);
          }, 0) }}
        </td>
      </tr>
    </template>
    <template v-slot:[`item.credit`]="{ item }">
      <v-chip variant="flat" v-if="item.credit && !isPaid(item)" label color="orange" class="">CR</v-chip>
      <v-chip variant="flat" v-if="isPaid(item)" label color="green" class="">PA</v-chip>
    </template>
    <template v-slot:[`item.payments_from_customer`]="{ item }">
      <p v-for="payment in item.payments_from_customer" :key="payment._id" class="text-decoration-underline text-right"
        :style="{ background: intToRGB(hashCode(payment.payment._id)), 'color': getContrastColor(intToRGB(hashCode(payment.payment._id))) }">
        <a @click="currentPayment = payment; paymentDialog = true;" link="">{{ payment.amount }}<span
            v-if="payment.payment.movement" class="">/P<span v-if="!payment.payment.movement._id">∎</span></span><span
            v-else>&nbsp;&nbsp;</span></a>
      </p>
    </template>
    <template v-slot:[`item.payments_to_provider`]="{ item }">
      <p v-for="payment in item.payments_to_provider" :key="payment._id" class="text-decoration-underline text-right"> <a
          @click="currentPayment = payment; paymentDialog = true; this.operation = item;" link="">{{ payment.amount
          }}<span v-if="payment.payment.movement">/P<span v-if="!payment.payment.movement._id">∎</span></span><span v-else
            style="background-color: orange">&nbsp;&nbsp;</span></a>
      </p>
    </template>

    <template v-slot:[`item.actions1`]="{ item }">
      <v-chip variant="flat" v-if="isPaidToProvider(item)" label color="primary" class="">PP</v-chip>
    </template>

    <template v-slot:[`item.sale_price`]="{ item }">
      <p><a @click="this.operation = item; amountDialogVisible = true;" link=""
          class="text-decoration-underline">$</a><span v-if="item.tmpCustomerAmount && item.tmpCustomerAmount !== 0">{{
            item.tmpCustomerAmount }}/</span>{{ item.sale_price }}
      </p>
    </template>
    <template v-slot:[`item.cost_total`]="{ item }">
      <p><a @click="this.operation = item; providerAmountDialogVisible = true;" link=""
          class="text-decoration-underline">$</a><span v-if="item.tmpProviderAmount && item.tmpProviderAmount !== 0">{{
            item.tmpProviderAmount }}/</span>{{ item.cost_total }}
      </p>
    </template>
    <template v-slot:[`item.sales_agent`]="{ item }">
      {{ item.sales_agent.lastName }}/{{ item.sales_agent.firstName }}
    </template>


    <template v-slot:[`item.doc_from_provider`]="{ item }">
      <pre
        v-if="!item.doc_from_provider || item.doc_from_provider === 'boleto_no_encontrado' || item.doc_from_provider === 'monto_no_encontrado'">{{ item.doc_from_provider }}</pre>
      <pre v-else @click="openWindowWithAuth('/cfdi/received/' + item.doc_from_provider + '.xml')"
        class="text-decoration-underline">{{ item.doc_from_provider }}</pre>
    </template>
    <template v-slot:[`item.reservation_code`]="{ item }">
      <div class="d-flex align-center" v-if="item.platform === 'sabre'">
        <v-menu>
          <template v-slot:activator="{ props }">
            <span v-bind="props" class="text-decoration-underline">{{ item.reservation_code }}</span>
          </template>
          <v-list>
            <v-list-item>
              <v-list-item-title size="small">
                <a target="_blank"
                  :href="'https://services.tripcase.com/new/itineraryChron.html?lastName=' + item.passengers.split('/')[0] + '&segment=print&action=printPreview&pnr=' + item.reservation_code + '&language=7'">
                  简体
                </a>
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title size="small">
                <a target="_blank"
                  :href="'https://services.tripcase.com/new/itineraryChron.html?lastName=' + item.passengers.split('/')[0] + '&segment=print&action=printPreview&pnr=' + item.reservation_code + '&language=8'">
                  繁体
                </a>
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>
                <a target="_blank"
                  :href="'https://services.tripcase.com/new/itineraryChron.html?lastName=' + item.passengers.split('/')[0] + '&segment=print&action=printPreview&pnr=' + item.reservation_code + '&language=1'">
                  ES
                </a>
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>
                <a target="_blank"
                  :href="'https://services.tripcase.com/new/itineraryChron.html?lastName=' + item.passengers.split('/')[0] + '&segment=print&action=printPreview&pnr=' + item.reservation_code + '&language=5'">
                  EN
                </a>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <div class="d-flex align-center" v-if="item.provider === 'volaris'">
        <v-menu>
          <template v-slot:activator="{ props }">
            <span v-bind="props" class="text-decoration-underline">{{ item.reservation_code }}</span>
          </template>
          <v-list>
            <v-list-item>
              <v-list-item-title size="small">
                <a target="_blank"
                  :href="'https://www.volaris.com?lastName=' + item.passengers.split('/')[0] + '&recordLocator=' + item.reservation_code + '&language=es-MX'">
                  PNR volaris
                </a>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <div class="d-flex align-center" v-if="item.provider === 'vivaaerobus'">
        <v-menu>
          <template v-slot:activator="{ props }">
            <span v-bind="props" class="text-decoration-underline">{{ item.reservation_code }}</span>
          </template>
          <v-list>
            <v-list-item>
              <v-list-item-title size="small">
                <a target="_blank"
                  :href="'https://www.vivaaerobus.com/es-mx/manage/trip-details?lastName=' + item.passengers.split('/')[0] + '&pnr=' + item.reservation_code">
                  PNR viva
                </a>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <span v-if="item.platform !== 'sabre' && item.provider !== 'volaris' && item.provider !== 'vivaaerobus'">{{
        item.reservation_code }}</span>
    </template>

    <template v-slot:[`item.date`]="{ item }">
      <pre>{{ $filters.toMexicoCityTime(item.date) }}</pre>
    </template>


    <template v-slot:[`item.odoo_so`]="{ item }">
      {{ item.odoo_so }}
    </template>

    <template v-slot:[`item.ticket_number`]="{ item }">
      <v-menu style="font-family: monospace;" :close-on-content-click="false"
        v-if="item.platform === 'sabre' && item.ticket_number">
        <template v-slot:activator="{ props }">
          <pre v-bind="props" class="text-decoration-underline"
            @click="tktinfo = ''; getDocDetailFromCarriers(item.ticket_number.split('/')[0])">{{ item.ticket_number }}<span v-bind="props" @click="tktinfo = ''; getDocDetail(item.ticket_number.split('/')[0])">.</span></pre>
        </template>
        <v-list>
          <v-list-item>
            <v-list-item-title size="small">
              <pre>{{ tktinfo }}</pre>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <pre v-else>{{ item.ticket_number }}</pre>
    </template>

    <template v-slot:[`item.passengers`]="{ item }">
      <v-menu style="font-family: monospace;" :close-on-content-click="false"
        v-if="item.passengers.split('\n').length > 1">
        <template v-slot:activator="{ props }">
          <pre v-bind="props" class="text-decoration-underline">{{ item.passengers.split('\n')[0] }}</pre>
        </template>
        <v-list>
          <v-list-item>
            <v-list-item-title size="small">
              <pre>{{ item.passengers }}</pre>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <pre v-else>{{ item.passengers }}</pre>
    </template>
    <template v-slot:[`item.description`]="{ item }">
      <v-menu style="font-family: monospace;" :close-on-content-click="false"
        v-if="item.description.split('\n').length > 1">
        <template v-slot:activator="{ props }">
          <pre v-bind="props" class="text-decoration-underline">{{ item.description.split('\n')[0] }}</pre>
        </template>
        <v-list>
          <v-list-item>
            <v-list-item-title size="small">
              <pre>{{ item.description }}</pre>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <pre v-else>{{ item.description }}</pre>
    </template>


    <template v-slot:[`item.actions`]="{ item }">
      <div class="d-flex align-center">
        <v-icon size="small" @click="dialog = `Modify`; this.operation = item;">
          mdi-pencil
        </v-icon>
      </div>
    </template>
    <template v-slot:[`item.actions0`]="{ item }">
      <v-menu style="font-family: monospace;">
        <template v-slot:activator="{ props }">
          <v-icon size="small" v-bind="props">
            mdi-menu
          </v-icon>
        </template>
        <v-list>
          <v-list-item
            @click="createPaymentToProvider; selected_operations.push(item._id); createPaymentToProvider(selected_operations); selected_operations.splice(0, selected_operations.length);">
            <v-list-item-title size="small">
              <v-icon size="small">
                mdi-sync
              </v-icon>
              Payment to Provider (solo)
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="getOdooSo(item)">
            <v-list-item-title size="small">
              <v-icon size="small">
                mdi-sync
              </v-icon>
              Sync Odoo So
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="operationTo = item; operationActionDialogVisible = 'unarchive'" v-if=" item.archived ">
            <v-list-item-title size="small">
              <v-icon size="small">
                mdi-archive-check
              </v-icon>
              Unarchive
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="operationTo = item; operationActionDialogVisible = 'archive'" v-else>
            <v-list-item-title size="small">
              <v-icon size="small">
                mdi-archive-check
              </v-icon>
              Archive
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title size="small">
              <v-icon size="small">
                mdi-calendar
              </v-icon>
              UpdatedAt: {{ $filters.toMexicoCityTime(item.updatedAt) }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title size="small">
              <v-icon size="small">
                mdi-calendar
              </v-icon>
              CreatedAt: {{ $filters.toMexicoCityTime(item.createdAt) }}
            </v-list-item-title>
          </v-list-item>

          <v-list-item @click="operationTo = item; operationActionDialogVisible = 'delete';">
            <v-list-item-title size="small">
              <v-icon size="small">
                mdi-delete
              </v-icon>
              Delete
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </v-data-table>
  <pre>
  </pre>
  <v-dialog v-model=" paymentDialog " max-width="500px" style="font-family: monospace;">
    <v-card>
      <v-card-title class="headline">
        Payment Details
      </v-card-title>
      <v-card-text>
        <p>_id: {{ currentPayment.payment._id }} <span @dblclick="deletePayment(currentPayment.payment)"
            v-if=" !currentPayment.payment.movement ">-</span></p>
        <p>Amount: {{ currentPayment.amount }} / {{ currentPayment.payment.amount }} </p>
        <p>Currency: {{ currentPayment.payment.currency }}</p>
        <p>Created By: {{ currentPayment.payment.created_by }}</p>
        <p>Created At: {{ $filters.toMexicoCityTime(currentPayment.payment.createdAt) }}</p>
        <p>Updated At: {{ $filters.toMexicoCityTime(currentPayment.payment.updatedAt) }}</p>
        <p>Reference: {{ currentPayment.payment.reference }}</p>
        <p v-if=" currentPayment.payment.movement && currentPayment.payment.movement.tsLiquidacion "
          class="text-decoration-underline">Liquidation Time: {{ $filters.toMexicoCityTime(new
          Date(Number(currentPayment.payment.movement.tsLiquidacion))) }}</p>
        <p>Movement: <span v-if=" !currentPayment.payment.movement "
            @click="showMovementForm = !showMovementForm">+/-</span>
        <pre v-if=" currentPayment.payment.movement ">{{ JSON.stringify(currentPayment.payment.movement, null, 2) }}</pre>
        </p>
        <div v-if=" showMovementForm && !currentPayment.payment.movement " class="mt-2">
          <v-form ref="movementForm" validate-on="submit blur" @submit.prevent=" submitMovementForm ">
            <v-text-field label="Fecha Operacion" v-model=" movementForm.fechaOperacion " type="date"></v-text-field>

            <v-autocomplete v-model=" movementForm.tipoPago " :items=" ['transfer', 'cash', 'check'] " auto-select-first
              label="Tipo Pago"></v-autocomplete>

            <v-autocomplete v-model=" movementForm.institucionOrdenante " :items=" config.detail.banks " auto-select-first
              item-title="name" item-value="id" label="Institucion Ordenante"
              v-if=" currentPayment.payment.amount < 0 "></v-autocomplete>
            <v-autocomplete v-model=" movementForm.cuentaOrdenante " :items=" config.detail.bankAccounts "
              auto-select-first v-if=" currentPayment.payment.amount < 0 " label="Cuenta Ordenante"></v-autocomplete>
            <v-text-field label="Nombre Ordenante" v-model=" movementForm.nombreOrdenante "
              v-if=" currentPayment.payment.amount < 0 "></v-text-field>

            <v-autocomplete v-model=" movementForm.institucionBeneficiaria " :items=" config.detail.banks "
              auto-select-first v-if=" currentPayment.payment.amount > 0 " item-title="name" item-value="id"
              label="Institucion Beneficiaria"></v-autocomplete>
            <v-autocomplete v-model=" movementForm.cuentaBeneficiario " :items=" config.detail.bankAccounts "
              v-if=" currentPayment.payment.amount > 0 " auto-select-first label="Cuenta Beneficiario"></v-autocomplete>
            <v-text-field label="Nombre Beneficiario" v-model=" movementForm.nombreBeneficiario "
              v-if=" currentPayment.payment.amount > 0 "></v-text-field>

            <v-text-field label="Monto" v-model.number=" movementForm.monto " type="number"></v-text-field>
            <v-autocomplete v-model=" movementForm.moneda " :items=" ['MXN', 'USD', 'CNY'] " auto-select-first
              label="Moneda"></v-autocomplete>
            <v-text-field label="Tipo de Cambio" v-model.number=" movementForm.tipoCambio " type="number"></v-text-field>
            <v-text-field label="Clave Rastreo" v-model=" movementForm.claveRastreo "></v-text-field>
            <v-text-field label="Concepto Pago" v-model=" movementForm.conceptoPago "></v-text-field>
            <v-text-field label="Referencia Numerica" v-model.number=" movementForm.referenciaNumerica "
              type="number"></v-text-field>
            <v-btn type="submit">Submit</v-btn>
            <v-btn color="blue darken-1 ml-2" text @click="showMovementForm = false">Close</v-btn>
          </v-form>
        </div>

        <v-divider class="mt-8"></v-divider>
        <div v-if=" currentPayment.payment.amount > 0 && !currentPayment.payment.movement && !showMovementForm ">
          <p class="mt-4">
            <strong>墨西哥启程旅游账号信息：</strong><br />
            <br />
            银行/BANCO: STP<br />
            帐户名/TITULAR: A DE RH INTERNACIONAL SA DE CV<br />
            转账账号/CLABE: 646180395200021292<br />
            <br />
            <strong>请您转账时：</strong><br />
            <br />
          <ul class="ml-4">
            <li>在备注栏<strong class="text-red-lighten-1">"CONCEPTO"</strong>填写：<strong class="pa-2"
                style="background-color: #eee;">{{ currentPayment.payment.reference }}</strong></li>
            <li class="mt-4">输入正确的金额/MONTO: {{ currentPayment.payment.amount }}</li>
          </ul>
          <br />
          <strong>注意：</strong>
          <br />
          <br />
          <ul class="ml-4">
            <li>需要输入正确的金额和备注才会转账成功。</li>
            <li>上述账号只支持手机或者网上银行转账使用。</li>
            <li>不支持现金存款。</li>
            <li>如有任何疑问，请联系我们客服。</li>
          </ul>

          </p>

          <v-divider class="mt-8"></v-divider>
          <p class="mt-4">
            <strong>CUENTA BANCARIA DE VAMONOZ VIAJES:</strong><br />
            <br />
            BANCO: STP<br />
            TITULAR: A DE RH INTERNACIONAL SA DE CV<br />
            CLABE: 646180395200021292<br />
            <br />
            <strong>Cuando se realiza la transferencia：</strong><br />
            <br />
          <ul class="ml-4">
            <li>Escriba en <strong class="text-red-lighten-1">"CONCEPTO"</strong>：<strong class="pa-2"
                style="background-color: #eee;">{{ currentPayment.payment.reference }}</strong></li>
            <li class="mt-4">Ingrese en Monto/Importe: {{ currentPayment.payment.amount }}</li>
          </ul>
          <br />
          <strong>Notas：</strong>
          <br />
          <br />
          <ul class="ml-4">
            <li>Solo acepta pagos referenciados con monto exacto.</li>
            <li>La cuenta solo acepta transferencia SPEI.</li>
            <li>No acepta pago/depósito en efectivo.</li>
            <li>Cualquier duda, favor de contactarnos.</li>
          </ul>
          </p>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" text @click="paymentDialog = false">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model=" operationActionDialogVisible " max-width="500px">
    <v-card>
      <v-card-title class="headline">Confirm {{ operationActionDialogVisible }} ?</v-card-title>
      <v-card-text>Do you really want to <strong>{{ operationActionDialogVisible }}</strong> this operation?</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" text @click="operationActionDialogVisible = false">Cancel</v-btn>
        <v-btn color="green darken-1" text @click="operationAction(operationActionDialogVisible)">{{
          operationActionDialogVisible }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ErrorDialog from './ErrorDialog.vue';

import axios from 'axios';

export default {
  name: 'OperationPage',
  props: ['operation_filter'],
  components: {
    ErrorDialog,
  },
  data() {
    return {
      itin_pnr: '',
      itin_lastname: '',
      tktinfo: '',
      config: JSON.parse(localStorage.getItem('config')),
      search: '',
      dialog: null,
      loading: false,
      operation: this.initialFormState(),
      operations: [],
      selected_operations: [],
      itemsPerPage: 25,
      errorDialogVisible: false,
      errorMessage: null,
      paymentDialog: false,
      currentPayment: null,
      operationTo: null,
      operationActionDialogVisible: null,
      amountDialogVisible: false,
      providerAmountDialogVisible: false,
      paymentAmount: 0,
      movementForm: {
        fechaOperacion: '',
        institucionOrdenante: '',
        institucionBeneficiaria: '',
        claveRastreo: '',
        monto: '',
        nombreOrdenante: '',
        cuentaOrdenante: '',
        nombreBeneficiario: '',
        cuentaBeneficiario: '',
        conceptoPago: '',
        referenciaNumerica: '',
        tipoPago: '',
      },
      showMovementForm: false,
      groupBy: [],
      headers: [
        { title: 'Type', value: 'type' },
        { title: 'Op', value: 'op' },
        { title: 'Platform', value: 'platform' },
        { title: 'Provider', value: 'provider' },
        { title: 'DK', value: 'customer' },
        { title: 'Date', value: 'date' },
        { title: 'PNR', value: 'reservation_code' },
        { title: 'Ticket Number', value: 'ticket_number' },
        { title: 'Passengers', value: 'passengers' },
        { title: 'Description', value: 'description' },
        { title: 'Cost Total', value: 'cost_total' },
        { title: 'Sale Price', value: 'sale_price' },
        { title: 'A', key: 'actions', sortable: false },
        { title: 'P/C', value: 'credit' },
        { title: 'A', key: 'actions0', sortable: false },
        { title: 'Payments from Customer', value: 'payments_from_customer' },
        { title: 'Payments to Provider', value: 'payments_to_provider' },
        { title: 'A', key: 'actions1', sortable: false },
        { title: 'Cost IVA', value: 'cost_iva' },
        { title: 'Sale IVA', value: 'sale_iva' },
        { title: 'Sales Agent', value: 'sales_agent' },
        { title: 'Doc Provider', value: 'doc_from_provider' },
        { title: 'Odoo SO', value: 'odoo_so' },
        { title: 'T', value: 'transaction' },
        { title: '_id', value: '_id' },
      ],
    }
  },
  async created() {
    // watch the params of the route to fetch the data again
    this.$watch(
      () => this.$route.params,
      async () => {
        try {
          const response = await axios.get(process.env.VUE_APP_API_BASE_URL + '/operation/' + this.$route.params.filter, {
            params: this.$route.query
          });
          this.operations = response.data;
        } catch (error) {
          console.error('Failed to fetch operations:', error.message);
        }
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    )
  },
  async mounted() {
    console.log('mounted');

  },
  computed: {
    selectedTotal() {
      let totals = this.operations
        .filter(operation => this.selected_operations.includes(operation._id))
        .reduce((totals, operation) => {
          let sale_price = operation.tmpCustomerAmount && operation.tmpCustomerAmount !== 0
            ? Number(operation.tmpCustomerAmount)
            : Number(operation.sale_price) - operation.payments_from_customer.reduce((total, payment) => total + Number(payment.amount), 0);
          let cost_total = operation.tmpProviderAmount && operation.tmpProviderAmount !== 0
            ? Number(operation.tmpProviderAmount)
            : Number(operation.cost_total) + operation.payments_to_provider.reduce((total, payment) => total + Number(payment.amount), 0);
          return {
            sale_price: totals.sale_price + (isNaN(sale_price) ? 0 : sale_price),
            cost_total: totals.cost_total + (isNaN(cost_total) ? 0 : cost_total),
          };
        }, { sale_price: 0, cost_total: 0 });

      return totals;
    },
    localDate: {
      get() {
        return this.operation.date ? this.$filters.toMexicoCityTime(this.operation.date) : null;
      },
      set(value) {
        this.operation.date = new Date(value);
      }
    }
  },
  methods: {
    initialFormState() {
      return {
        transaction: 'sale',
        platform: '',
        date: '',
        type: '',
        op: '',
        customer: '',
        provider: '',
        sales_agent: '',
        sale_price: null,
        reservation_code: '',
        ticket_number: '',
        passengers: '',
        description: '#',
        cost_total: null,
        cost_iva: null,
        odoo_so: '',
        odoo_po: '',
        payments_to_provider: [],
        payments_from_customer: [],
        doc_from_provider: '',
        doc_to_customer: '',
        credit: false,
        // Add more fields as needed
      };
    },
    async submitForm() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.loading = true;
        if (this.dialog === 'Create') {
          try {
            const response = await axios.post(process.env.VUE_APP_API_BASE_URL + '/operation/create', this.operation);
            this.operations.unshift(response.data);
            this.dialog = null;
          } catch (error) {
            this.errorMessage = 'Failed to create operation: ' + error.message + ' ' + error.response.data.message;
            this.errorDialogVisible = true;
          }
        } else if (this.dialog === 'Modify') {
          try {
            const response = await axios.put(process.env.VUE_APP_API_BASE_URL + '/operation/' + this.operation._id, this.operation);
            console.log(response.data);
            this.operation = response.data;
            let index = this.operations.findIndex(op => op._id === this.operation._id);
            if (index !== -1) {
              this.operations[index] = this.operation;
            }
            this.dialog = null;
          } catch (error) {
            console.error(error);
            this.errorMessage = 'Failed to modify operation: ' + error.message + ' ' + error.response.data.message;
            this.errorDialogVisible = true;
          }
        }
        this.loading = false;
      }
    },
    cleanForm() {
      this.operation = this.initialFormState();
      this.$refs.form.reset();
    },
    async submitMovementForm() {
      const { valid } = await this.$refs.movementForm.validate();
      this.movementForm.__v = this.currentPayment.payment.__v;
      if (valid) {
        try {
          const response = await axios.put(process.env.VUE_APP_API_BASE_URL + '/payment/movement/' + this.currentPayment.payment._id, this.movementForm);
          this.currentPayment.payment = response.data;
          this.showMovementForm = false;
        } catch (error) {
          this.errorMessage = 'Failed to create movement: ' + error.message + ' ' + error.response.data.message;
          this.errorDialogVisible = true;
        }
      }
    },
    setDateToToday() {
      let today = new Date();
      let yyyy = today.getFullYear();
      let mm = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero based
      let dd = String(today.getDate()).padStart(2, '0');
      this.operation.date = `${yyyy}-${mm}-${dd}`;
    },
    generateGradient(type, op, platform, provider) {
      const hash = this.hashCode(type + op + platform + provider);
      const color2 = this.intToRGB(hash); // Offset to create a different color for the gradient
      return `linear-gradient(to right, #ffffff, #ffffff, #${color2})`;
    },
    hexToRgb(hex) {
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      } : null;
    },
    getContrastColor(hex) {
      const rgb = this.hexToRgb(hex);
      if (!rgb) {
        return null;
      }
      const luminance = (0.299 * rgb.r + 0.587 * rgb.g + 0.114 * rgb.b) / 255;
      return luminance > 0.5 ? '#000' : '#fff';
    },
    hashCode(str) {
      let hash = 0;
      for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      return hash;
    },
    intToRGB(i) {
      const c = (i & 0x00FFFFFF)
        .toString(16)
        .toUpperCase();
      return '#' + "00000".substring(0, 6 - c.length) + c;
    },
    async openWindowWithAuth(url) {
      try {
        const response = await axios.get(process.env.VUE_APP_API_BASE_URL + url, {
          responseType: 'blob',
        });
        const data = new Blob([response.data], { type: response.headers['content-type'] });
        const objectURL = window.URL.createObjectURL(data);
        window.open(objectURL);
      } catch (error) {
        console.error('There has been a problem with your fetch operation:', error);
      }
    },
    isPaid(operation) {
      let sum = 0;
      let allPaymentsHaveMovement = true;

      operation.payments_from_customer.forEach(payment => {
        sum += payment.amount;
        if (!payment.payment.movement) {
          allPaymentsHaveMovement = false;
        }
      });

      return Number((operation.sale_price - sum).toFixed(5)) === 0 && allPaymentsHaveMovement;
    },
    isPaidToProvider(operation) {
      let sum = 0;
      let allPaymentsHaveMovement = true;

      operation.payments_to_provider.forEach(payment => {
        sum += payment.amount;
        if (!payment.payment.movement) {
          allPaymentsHaveMovement = false;
        }
      });

      return Number((operation.cost_total + sum).toFixed(5))=== 0 && allPaymentsHaveMovement;
    },
    async operationAction(action) {
      switch (action) {
        case 'archive':
          await this.archiveOperation(this.operationTo);
          break;
        case 'unarchive':
          await this.unarchiveOperation(this.operationTo);
          break;
        case 'delete':
          await this.deleteOperation(this.operationTo);
          break;
        default:
          // handle default case if necessary
          break;
      }
      this.operationActionDialogVisible = false;
    },
    async archiveOperation(item) {
      try {
        await axios.put(process.env.VUE_APP_API_BASE_URL + '/operation/' + item._id + "/archive", { archived: !item.archive, __v: item.__v });
        const index = this.operations.findIndex(operation => operation._id === item._id);
        if (index !== -1) {
          this.operations.splice(index, 1);
        }
      } catch (err) {
        this.errorMessage = 'Failed to archive operation: ' + err.message + ' ' + err.response.data.message;
        this.errorDialogVisible = true;
      }
    },
    async unarchiveOperation(item) {
      try {
        await axios.put(process.env.VUE_APP_API_BASE_URL + '/operation/' + item._id + "/archive", { archived: false, __v: item.__v });
        const index = this.operations.findIndex(operation => operation._id === item._id);
        if (index !== -1) {
          this.operations.splice(index, 1);
        }
      } catch (err) {
        this.errorMessage = 'Failed to unarchive operation: ' + err.message + ' ' + err.response.data.message;
        this.errorDialogVisible = true;
      }
    },
    async deleteOperation(item) {
      try {
        await axios.delete(process.env.VUE_APP_API_BASE_URL + '/operation/' + item._id + "/v/" + item.__v);
        const index = this.operations.findIndex(operation => operation._id === item._id);
        this.operations.splice(index, 1);
      } catch (err) {
        this.errorMessage = 'Failed to delete operation: ' + err.message + ' ' + err.response.data.message;
        this.errorDialogVisible = true;
      }
    },
    async getOdooSo(operation) {
      try {
        const response = await axios.get(process.env.VUE_APP_API_BASE_URL + '/operation/odoo/so/' + operation._id + "/v/" + operation.__v);
        operation.cost_iva = response.data.cost_iva;
        operation.odoo_so = response.data.odoo_so;
        operation.__v = response.data.__v;
        operation.sale_iva = response.data.sale_iva;
      } catch (err) {
        this.errorMessage = 'Failed to get odoo so: ' + err.message + ' ' + err.response.data.message;
        this.errorDialogVisible = true;
      }
    },
    async deletePayment(payment) {
      try {
        await axios.delete(process.env.VUE_APP_API_BASE_URL + '/payment/' + payment._id + "/v/" + payment.__v);
        //find operations which contains this payment
        const operations = this.operations.filter(operation => {
          return operation.payments_from_customer.find(p => p.payment._id === payment._id)
            || operation.payments_to_provider.find(p => p.payment._id === payment._id);
        });
        //remove payment from operations
        operations.forEach(operation => {
          operation.payments_from_customer = operation.payments_from_customer.filter(p => p.payment._id !== payment._id);
          operation.payments_to_provider = operation.payments_to_provider.filter(p => p.payment._id !== payment._id);
        });
        //close payment dialog
        this.paymentDialog = false;
      } catch (err) {
        this.errorMessage = 'Failed to delete operation: ' + err.message + ' ' + err.response.data.message;
        this.errorDialogVisible = true;
      }
    },
    async getDocDetail(ticket_number) {
      try {
        const response = await axios.get(process.env.VUE_APP_API_BASE_URL + '/operation/tkt/' + ticket_number);
        this.tktinfo = response.data;
      } catch (err) {
        this.errorMessage = 'Failed to get doc detail: ' + err.message + ' ' + err.response.data.message;
        this.errorDialogVisible = true;
      }
    },
    async getDocDetailFromCarriers(ticket_number) {
      try {
        const response = await axios.get(process.env.VUE_APP_API_BASE_URL + '/operation/tktFromCarriers/' + ticket_number);
        this.tktinfo = response.data;
      } catch (err) {
        this.errorMessage = 'Failed to get doc detail from carriers: ' + err.message + ' ' + err.response.data.message;
        this.errorDialogVisible = true;
      }
    },
    async createPaymentFromCustomer(selected_operations) {
      const operations = this.operations.filter(operation => selected_operations.includes(operation._id));

      const payload = operations.map(operation => {
        if (operation.tmpCustomerAmount && operation.tmpCustomerAmount !== 0) {
          return { _id: operation._id, __v: operation.__v, amount: operation.tmpCustomerAmount };
        } else {
          const totalPayments = operation.payments_from_customer.reduce((total, payment) => total + payment.amount || 0, 0);
          const difference = operation.sale_price - totalPayments;
          return { _id: operation._id, __v: operation.__v, amount: difference };
        }
      });

      try {
        const response = await axios.post(process.env.VUE_APP_API_BASE_URL + '/payment/create/fromcustomer', payload);
        const newPayment = response.data;
        // push amount from payload and newPayment to operation.payments_from_customer
        // loop payload
        // find operation in this.operations
        // push payload.amount and newPayment to operation.payments_from_customer
        // TODO: if successful, just retrieve the new operation from the server
        for (let i = 0; i < payload.length; i++) {
          const p = payload[i];
          const operation = this.operations.find(operation => operation._id === p._id);
          operation.payments_from_customer.push({ amount: p.amount, payment: newPayment });
          delete operation.tmpCustomerAmount;
        }

      } catch (error) {
        this.errorMessage = 'Failed to create payment from customer: ' + error.message + ' ' + error.response.data.message;
        this.errorDialogVisible = true;
      }
    },
    async createPaymentToProvider(selected_operations) {
      const operations = this.operations.filter(operation => selected_operations.includes(operation._id))

      try {

        operations.forEach(operation => {
          if (!operation.ticket_number || operation.ticket_number.trim() === "") {
            throw new Error('Ticket Number is required for operation: ' + operation._id);
          }
        });
      } catch (error) {
        this.errorMessage = 'Failed to create payment to provider: ' + error;
        this.errorDialogVisible = true;
        return
      }

      const payload = operations.map(operation => {
        if (operation.tmpProviderAmount && operation.tmpProviderAmount !== 0) {
          return { _id: operation._id, __v: operation.__v, amount: operation.tmpProviderAmount, reference: operation.ticket_number };
        } else {
          // payment.amount <= 0
          const totalPayments = operation.payments_to_provider.reduce((total, payment) => total + payment.amount || 0, 0);
          const difference = -(operation.cost_total + totalPayments);
          return { _id: operation._id, __v: operation.__v, amount: difference, reference: operation.ticket_number };
        }
      });

      try {
        const response = await axios.post(process.env.VUE_APP_API_BASE_URL + '/payment/create/toprovider', payload);
        const newPayment = response.data;
        // push amount from payload and newPayment to operation.payments_from_customer
        // loop payload
        // find operation in this.operations
        // push payload.amount and newPayment to operation.payments_from_customer
        // TODO: if successful, just retrieve the new operation from the server
        for (let i = 0; i < payload.length; i++) {
          const p = payload[i];
          const operation = this.operations.find(operation => operation._id === p._id);
          operation.payments_to_provider.push({ amount: p.amount, payment: newPayment });
        }

      } catch (error) {
        this.errorMessage = 'Failed to create payment to provider: ' + error.message + ' ' + error.response.data.message;
        this.errorDialogVisible = true;
      }
    },
  },
}
</script>

<!--
  todo:
    - Paint payment from customer with different color depending value of its reference so user can identify if it applies to different operations
    - Rservation print button 

-->