import { createStore } from 'vuex'

export default createStore({
  state: {
    isLoggedIn: localStorage.getItem('token') ? true : false,
    isLoading: false,
  },
  mutations: {
    setLoggedIn(state, value) {
      state.isLoggedIn = value
    },
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
  },
})