<template>
    <div class="mt-16">
        <v-row justify="center">
            <v-col cols="12" sm="8" md="4">
                <v-form @submit.prevent="submitForm">
                    <v-row>
                        <v-col cols="12">
                            <v-text-field label="Username" v-model="username" required></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field label="Password" type="password" v-model="password" required></v-text-field>
                        </v-col>
                    </v-row>
                    <v-btn type="submit">Login</v-btn>
                </v-form>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'LoginPage',
    data() {
        return {
            username: '',
            password: '',
            error: null,
        };
    },
    created() {
        if (localStorage.getItem('token')) {
            this.$router.push('/')
        }
    },
    methods: {
        async submitForm() {
            try {
                const response = await axios.post(process.env.VUE_APP_API_BASE_URL + '/user/login', {
                    username: this.username,
                    password: this.password,
                });
                // check status code
                if (response.status === 200) {
                    // store the token in localstorage
                    // redirect to the home page
                    localStorage.setItem('token', response.data.token);
                    localStorage.setItem('config', JSON.stringify(response.data.config));
                    this.$store.commit('setLoggedIn', true)
                    this.$router.push('/');
                } else {
                    // show error message
                    this.error = response.data.message;
                }
            } catch (error) {
                // Handle login error here, e.g. by showing an error message to the user
                this.error = error.response.data.message;
            }
        },
    },
};
</script>