<template>
    <v-dialog v-model="visible" max-width="500px">
        <v-card>
            <v-card-title class="headline">Error</v-card-title>
            <v-card-text>{{ errorMessage }}</v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="closeDialog">Close</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: ['dialogVisible', 'errorMessage'],
    computed: {
        visible: {
            get() {
                return this.dialogVisible;
            },
            set(value) {
                this.$emit('update:dialogVisible', value);
            }
        }
    },
    methods: {
        closeDialog() {
            this.visible = false;
        }
    }
};
</script>